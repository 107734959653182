'use strict';

Gri.module({
  name: 'footer-alpha',
  ieVersion: null,
  $el: $('.footer-alpha'),
  container: '.footer-alpha',
  fn: function () {

    const $this = this.$el;

    resizeController();
    $(window).on('resize', resizeController);

    function resizeController() {
      let windowW = $(window).width();
      if(windowW >= 992) {
        let footerH = $this.outerHeight();
        $('.layout').css('margin-bottom', footerH);
      }
    }

  }
});
